<template>
  <ValidationObserver ref="observer" tag="div" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(validate)">
      <div class="w-full">
        <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseInput type="text" label="Title" name="title" class="text-gray-700 text-base" v-model="payload.title" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>
      </div>

      <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseInput type="text" label="Legend" name="legend"  class="text-gray-700 text-base" v-model="payload.legend" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>

        <BaseInput type="text" label="Sort order" name="sort_order"  class="text-gray-700 text-base" v-model="payload.sort_order" />

      <BaseBtn type="submit" :text="payload.id ? 'Update' : 'Add'" />
      <FlashMessage :message="message" :error="error" />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  //setInteractionMode,
} from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

import { mapGetters } from "vuex";
import { getError } from "@/utils/helpers";
import BaseBtn from "@/components/BaseBtn";
import BaseInput from "@/components/BaseInput";
import TopicService from "@/services/TopicService";
import FlashMessage from "@/components/FlashMessage";
import BaseSelect from "@/components/BaseSelect";

export default {
  name: "TopicModel",
  components: {
    BaseBtn,
    BaseInput,
    BaseSelect,
    FlashMessage,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    attributes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      payload: {
        title: "",
        legend: "",
      },
      error: null,
      message: null,
    };
  },
  computed: {
    ...mapGetters("topic", ["add", "update"]),
  },
  methods: {
    validate() {
      this.error = null;
      this.message = null;
      console.log("this.payload", this.payload);

      if (this.payload.id) {
        TopicService.update(this.payload)
          .then(() => {
            this.message = "Topic updated.";
            this.$parent.close();
            this.$eventBus.$emit("topics:updated", true);
          })
          .catch((error) => (this.error = getError(error)));
      } else {
        TopicService.add(this.payload)
          .then(() => {
            this.message = "Topic added.";
            this.$parent.close();
            this.$eventBus.$emit("topics:added", true);
          })
          .catch((error) => (this.error = getError(error)));
      }
    },
  },
  mounted() {
      if (this.attributes.area_id) {
      this.payload.area_id = this.attributes.area_id;
    }

    if (this.attributes.payload) {
      this.payload = this.attributes.payload;
    }
  },
};
</script>
